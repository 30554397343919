import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {faStar} from '@fortawesome/pro-solid-svg-icons/faStar'
import {DecimalPipe} from '@angular/common'
import {I18nDirective} from '../i18n/i18n.directive'

const GOOGLE_REVIEW_MAX_RATING = 5

@Component({
  selector: 'cft-google-rating',
  templateUrl: './google-rating.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FontAwesomeModule, I18nDirective, DecimalPipe],
  styles: [
    `
      :host {
        @apply flex flex-row items-center justify-center gap-x-xs;
        @apply rounded border border-grey-400;
        @apply max-w-fit bg-white;
      }
    `,
  ],
})
export class GoogleRatingComponent {
  protected readonly starIcon = faStar

  @Input({required: true})
  set rating(value: number | string) {
    const rating = isNaN(Number(value)) ? 0 : Number(value)
    this._rating = Math.min(GOOGLE_REVIEW_MAX_RATING, rating)
  }
  get rating() {
    return this._rating
  }
  private _rating = 0

  @Input() numReviews?: number
}
