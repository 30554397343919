<ng-container *cftI18n="let t">
  <fa-icon [icon]="starIcon" class="pl-xs text-yellow-500"></fa-icon>
  <div class="flex items-center gap-xs truncate">
    <span class="text-sm font-semibold">
      {{ rating | number: '1.1-2' }}
    </span>
    @if (numReviews && numReviews > 0) {
      <span class="text-xs text-grey-600"> ({{ t('shared.reviews.num', {num: numReviews}) }}) </span>
    }
  </div>
  <div class="border-l border-grey-400 p-2xs">
    <img class="block h-[1.125rem] w-[1.125rem]" alt="" src="/assets/images/google-no-background.svg" />
  </div>
</ng-container>
